<template>
  <div class="page-content">
    <div class="container-fluid">
      <!-- start page title -->
      <div class="row">
        <div class="col-12">
          <div class="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 class="mb-sm-0 font-size-18">Top Tuần</h4>
            <div class="page-title-right">
              <ol class="breadcrumb m-0">
                <li class="breadcrumb-item">
                  <a href="javascript: void(0);">Admin Traodoicheo</a>
                </li>
                <li class="breadcrumb-item active">Top Tuần</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <!-- end page title -->
      <div class="row">
        <div class="col-12">
          <div class="card py-4">
            <div class="container-fluid">
              <div class="row mb-4">
                <div class="col-12 fw-bold">
                  <h5 class="text-primary">Chọn ngày</h5>
                </div>
                <div class="col-12">
                  <date-range-picker
                    ref="picker"
                    :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    v-model="dateRange"
                    :autoApply="true"
                    opens="right"
                    :show-week-numbers="false"
                    class="issue__filter__time_picker w-100"
                    custom-range-label="Chọn ngày bắt đầu - kết thúc"
                    :singleDatePicker="true"
                    :show-ranges="false"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 fw-bold">
                  <h5 class="text-primary">Thời gian đang xem</h5>
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày</label>
                  <input type="text" class="form-control" disabled v-model="date" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày bắt đầu tuần</label>
                  <input
                    type="text"
                    class="form-control"
                    disabled
                    v-model="dateRangeToShow.start"
                  />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Ngày kết thúc tuần</label>
                  <input type="text" class="form-control" disabled v-model="dateRangeToShow.end" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Tuần trong tháng</label>
                  <input type="text" class="form-control" disabled v-model="info.week_of_month" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Tuần trong năm</label>
                  <input type="text" class="form-control" disabled v-model="info.week" />
                </div>
                <div class="col-12 col-sm-6 col-md-3 mb-3">
                  <label for="">Tháng</label>
                  <input type="text" class="form-control" disabled v-model="info.month" />
                </div>
              </div>
              <loading-vue v-if="loading" />
              <statistics-top-table-vue :data="topList" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import statisticsApi from "@/api/statistics";
import LoadingVue from "@/components/loading/Loading.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import { buildParamQuery } from "@/helpers";
import StatisticsTopTableVue from "./StatisticsTopTable.vue";

export default {
  name: "StatisticsTop",
  components: {
    LoadingVue,
    DateRangePicker,
    StatisticsTopTableVue,
  },
  data() {
    return {
      username: null,
      object_id: null,
      limit: 50,
      data: {},
      loading: false,
      dateRange: {
        startDate: new Date(),
        endDate: new Date(),
      },
      info: {},
    };
  },
  computed: {
    date() {
      const date = this.dateRange.startDate;
      if (date) {
        return moment(date).format("YYYY-MM-DD");
      }
      return moment(new Date()).format("YYYY-MM-DD");
    },
    dateRangeToShow() {
      return this.info.date_range || {};
    },
    topList() {
      const { data } = this;
      let result = [];
      for (let field in data) {
        let obj = {
          user: field,
          coin: data[field],
        };
        result.push(obj);
      }
      return result;
    },
  },
  created() {
    this.getLogsList();
  },
  watch: {
    date() {
      this.getLogsList();
    },
  },
  methods: {
    async getLogsList() {
      const params = {
        date: this.date,
      };
      const queries = buildParamQuery(params);
      this.loading = true;
      const response = await statisticsApi.listStatisticsTop(queries);
      this.loading = false;
      if (response) {
        if (response.success) {
          this.data = response.data || {};
          this.info = response.info || {};
        } else {
          this.$toastr.error(response?.message || "Tải danh sách top tuần không thành công !");
          this.info = {};
          this.data = {};
        }
      } else {
        this.$toastr.error("Tải danh sách top tuần không thành công !");
        this.info = {};
        this.data = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
