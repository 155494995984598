<template>
  <div class="row mt-4">
    <div class="col-12 fw-bold mb-3">
      <h5 class="text-primary">Bảng xếp hạng</h5>
    </div>
    <div class="col-12">
      <div>
        <div
          v-for="(log, index) in logs"
          :key="index"
          class="top-item bg-white d-flex align-items-center justify-content-between py-2 px-3 mb-3"
        >
          <div class="">
            <p class="fw-bold text-secondary mb-1">{{ log.user }}</p>
            <span class="text-secondary">Không có thưởng</span>
          </div>
          <div class="text-end">
            <button
              :class="[
                'btn py-0',
                log.rank === 1 ? 'btn-danger' : log.rank === 2 ? 'btn-success' : 'btn-info',
              ]"
            >
              Top {{ log.rank }}
            </button>
            <p class="text-secondary mb-0 mt-1">{{ log.coin | formatNumber }} xu</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
  },
  name: "TopTable",
  computed: {
    logs() {
      return this.data.map((log, index) => {
        log.rank = index + 1;
        return log;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.top-item {
  box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.15);
  border-radius: 15px;
}
</style>
